export default {
  colors: {
    button: "#626262",
    buttonHover: "#1d1d1d",
    buttonHoverText: "white",
    editorText: "#3C3C3C",
    focusedText: "#C8C8C8",
    background: "white",
    menuHover: "#d2d3d4"
  },
};
