import React, { useState } from "react";
import { FormGroup } from "../SharedStyles";
import { downloadFile, serializeRtf, serializePlain } from "../Utils";
import styled from "styled-components";

const SaveMenu = ({ className, editorValue }) => {
  const [filename, setFilename] = useState("document");

  const [format, setFormat] = useState(".rtf");

  const save = (fileName, format) => {
    if (format === ".rtf") {
      const richText = serializeRtf(editorValue.get);
      const rtfWrapped = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\nouicompat\\deflang2057{\\fonttbl{\\f0\\fnil\\fcharset0 Calibri;}}{\\*\\generator Riched20 10.0.18362}\\viewkind4\\uc1 \\pard\\sa200\\sl276\\slmult1\\f0\\fs20\\lang9${richText}}`;

      downloadFile(rtfWrapped, fileName + format, "text/rtf");
    } else {
      const plainText = serializePlain(editorValue.get);

      downloadFile(plainText, fileName + format, "text");
    }
  };

  const saveBrowser = (fileName) => {
    let storedFiles = JSON.parse(localStorage.getItem("files"));

    localStorage.setItem(fileName, JSON.stringify(editorValue.get));

    if (storedFiles) {
      if (!storedFiles.includes(fileName))
        localStorage.setItem(
          "files",
          JSON.stringify([...storedFiles, fileName])
        );
    } else {
      localStorage.setItem("files", JSON.stringify([fileName]));
    }
  };

  return (
    <div className={className}>
      <FormGroup>
        <label>Filename</label>
        <input
          onChange={(e) => setFilename(e.target.value)}
          type="text"
        ></input>
      </FormGroup>
      <FormGroup>
        <label>Format</label>
        <select onChange={(e) => setFormat(e.target.value)}>
          <option value={".rtf"}>Rich Text Format (.rtf)</option>
          <option value={".txt"}>Plain Text (.txt)</option>
        </select>
      </FormGroup>
      <button
        onClick={() => {
          saveBrowser(filename);
        }}
      >
        Save
      </button>
      <button
        onClick={() => {
          save(filename, format);
        }}
      >
        Download
      </button>
      <p>
        Warning: saved files are <br />
        stored in your browser <br />
        and can be lost through <br />
        cookie & history deletion
      </p>
    </div>
  );
};

const StyledSaveMenu = styled(SaveMenu)`
  button {
    margin-right: 1em;
  }
  p {
    font-size: 13px;
  }
`;

export default StyledSaveMenu;
