import styled from "styled-components";

export const FormGroup = styled.div`
  label {
    display: block;
    color: ${(props) => props.theme.colors.text};
  }

  margin-bottom: 1em;
  font-size: 15px;

  select {
    border-radius: 3px;
    border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
    padding: 0.6em;
    margin-top: 0.5em;
    box-sizing: border-box;
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }

  input {
    border-radius: 3px;
    border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
    padding: 0.6em;
    margin-top: 0.5em;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    box-sizing: border-box;
  }
`;

export const UnorderedList = styled.ul`
  font-size: 32px;
  margin: 0;
  list-style-type: none;
  padding: 0;
`;
