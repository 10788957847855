import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Range, Editor } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import styled from "styled-components";

const HoveringToolbar = ({ toggleMark, isMarkActive }) => {
  const ref = useRef();
  const editor = useSlate();

  const FormatButton = ({
    mark,
    activeVal = true,
    border = true,
    children,
  }) => {
    const editor = useSlate();

    const isActive = isMarkActive(editor, mark, activeVal);


    return (
      <Button
        border={border}
        isActive={isActive}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleMark(editor, mark, activeVal);
        }}
      >
        {children}
      </Button>
    );
  };

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = 1;
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <Toolbar ref={ref}>
        <FormatButton mark="heading" activeVal="h1">
          H1
        </FormatButton>
        <FormatButton mark="heading" activeVal="h2">
          H2
        </FormatButton>
        <FormatButton mark="heading" activeVal="h3">
          H3
        </FormatButton>
        <FormatButton mark="bold">B</FormatButton>
        <FormatButton border={false} mark="italic">
          I
        </FormatButton>
      </Toolbar>
    </Portal>
  );
};

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const Button = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.isActive ? props.theme.colors.buttonHover : ""};
  font-weight: ${(props) => props.isActive ? "bold" : "normal"};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
  }
  border-radius: ${(props) => (props.border ? "4px 0 0 4px" : "0 4px 4px 0")};
  border-right: ${(props) =>
    props.border ? "1px solid rgba(var(--b6a, 219, 219, 219), 1)" : "none"};
  padding: 0.5em;
  width: 3em;
`;

const Toolbar = styled.div`
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: ${(props) => props.theme.colors.button};
  border-radius: 4px;
  transition: opacity 0.75s;
`;

export default HoveringToolbar;
