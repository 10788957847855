export default {
  colors: {
    // button: "rgb(227, 230, 232)",
    button: "#cccfd1",
    buttonHover: "white",
    buttonHoverText: "rgb(98, 98, 98)",
    editorText: "white",
    focusedText: "rgb(52, 52, 52)",
    text: "white",
    background: "rgb(29,29,29)",
    menuHover: "rgb(98, 98, 98)"
  },
};
