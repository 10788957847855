/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FormGroup, UnorderedList } from "../SharedStyles";
import { deserializeRtf, deserializePlain } from "../Utils";

const OpenMenu = ({ editorValue, className }) => {
  const [files, setFiles] = useState([]);
  const openInput = useRef(null);

  useEffect(() => {
    var storedFiles = JSON.parse(localStorage.getItem("files"));
    setFiles(storedFiles);
  }, []);

  const readSingleFile = (e) => {
    var file = e.target.files[0];

    if (!file) {
      return;
    }

    var extension = file.name.split(".").pop().toLowerCase();

    var reader = new FileReader();
    reader.onload = async (e) => {
      var contents = e.target.result;

      if (extension === "rtf") {
        editorValue.set(await deserializeRtf(contents));
      } else {
        editorValue.set(await deserializePlain(contents));
      }
    };

    reader.readAsText(file);
  };

  return (
    <div className={className}>
      <OpenInput ref={openInput} onChange={readSingleFile} type="file" />
      <FormGroup>
        <label>Browser Files</label>
        <UnorderedList>
          {files ? (
            files.map((f) => (
              <a
              className="irrelevant"
                onClick={(e) => {
                  e.preventDefault();
                  editorValue.set(JSON.parse(localStorage.getItem(f)));
                }}
              >
                <li>{f}</li>
              </a>
            ))
          ) : (
            <li>No Files Found</li>
          )}
        </UnorderedList>
      </FormGroup>
      <button
        onClick={() => {
          openInput.current.click();
        }}
      >
        Open From PC
      </button>
    </div>
  );
};

const StyledOpenMenu = styled(OpenMenu)`
  ul {
    font-size: 13px;

    margin-top: 0.5em;

    border-bottom: 1px solid rgba(var(--b6a, 219, 219, 219), 1);

    max-height: 12em;
    overflow-y: auto;
  }

  li {
    padding: 0.6em;
    border-top: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
  }
`;

const OpenInput = styled.input`
  display: none;
`;

export default StyledOpenMenu;
