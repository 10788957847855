/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { Howl } from "howler";
import { PlayIcon, PauseIcon } from "./Icons";

const Mixer = ({ className, display }) => {
  const [playing, setPlaying] = useState(false);

  const [pauseCache, setPauseCache] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [sounds, setSounds] = useState({
    fire: new Howl({
      src: ["sounds/fire.mp3"],
      loop: true,
    }),
    waves: new Howl({
      src: ["sounds/waves.mp3"],
      loop: true,
    }),
    rain: new Howl({
      src: ["sounds/rain.mp3"],
      loop: true,
    }),
  });

  const playSound = (sound, volume) => {
    const selectedSound = sounds[sound];
    const adjustedVolume = volume / 100;

    if (!selectedSound.playing()) {
      selectedSound.play();
      selectedSound.volume(adjustedVolume);
      setPlaying(true);
    } else {
      selectedSound.volume(adjustedVolume);
    }

    // Check if all sounds are at volume 0
    if (adjustedVolume === 0) {
      const allSoundsZero = Object.values(sounds).every(
        (s) => s.volume() === 0 || !s.playing()
      );
      if (allSoundsZero) {
        pause();
      }
    }
  };

  const unpause = () => {
    if (pauseCache.length > 0 && !playing) {
      pauseCache.forEach((cs) => playSound(cs.sound, cs.volume));
      setPlaying(true);
      setPauseCache([]);
    }
  };

  const pause = () => {
    Object.entries(sounds).forEach((s) => {
      if (s[1].playing()) {
        s[1].pause();
        setPauseCache((pauseCache) =>
          pauseCache.concat({ sound: s[0], volume: s[1].volume() * 100 })
        );
      }
    });

    setPlaying(false);
  };

  const toggle = () => {
    if (playing) {
      pause();
    } else {
      unpause();
    }
  };

  return (
    <div
      style={{ display: display ? "inherit" : "none" }}
      className={className}
    >
      <Control>
        <MenuButton
          onClick={() => {
            toggle();
          }}
        >
          {playing ? <PauseIcon /> : <PlayIcon />}
        </MenuButton>
      </Control>
      <Sound>
        <label>Waves</label>
        <input
          type="range"
          min="0"
          max="100"
          defaultValue="0"
          onChange={(e) => playSound("waves", e.target.value)}
        />
      </Sound>
      <Sound>
        <label>Fire</label>
        <input
          type="range"
          min="0"
          max="100"
          defaultValue="0"
          onChange={(e) => playSound("fire", e.target.value)}
        />
      </Sound>
      <Sound>
        <label>Rain</label>
        <input
          type="range"
          min="0"
          max="100"
          defaultValue="0"
          onChange={(e) => playSound("rain", e.target.value)}
        />
      </Sound>
    </div>
  );
};

const StyledMixer = styled(Mixer)`
  padding-right: 2em;
`;

const Sound = styled.div`
  label {
    display: block;
    color: ${(props) => props.theme.colors.text};
  }

  input {
    width: 100%;
  }

  input[type="range"] {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.colors.focusedText};
    height: 5px;
  }

  input[type="range"]::-moz-range-track {
    background: ${(props) => props.theme.colors.focusedText};
    height: 5px;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background: ${(props) => props.theme.colors.button};
    margin-top: -5px;
    border-radius: 50%;
  }

  input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background: ${(props) => props.theme.colors.button};
    margin-top: -5px;
    border-radius: 50%;
  }

  margin-bottom: 1em;
  font-size: 15px;

  padding-right: 0;
`;

const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MenuButton = styled.a`
  display: inline-block;
  font-size: 32px;
  padding: 0;
  margin-bottom: 0.3em;
  cursor: pointer;
`;
export default StyledMixer;
