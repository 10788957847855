/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { FormGroup, UnorderedList } from "../SharedStyles";
import ControlsContext from "./ControlsContext.js";
import Mixer from "./Mixer";
import SaveMenu from "./SaveMenu";
import OpenMenu from "./OpenMenu";

import {
  MenuIcon,
  SaveIcon,
  OpenIcon,
  MaxIcon,
  MinIcon,
  CrossIcon,
  CogIcon,
  MixIcon,
} from "./Icons";

const Menu = ({
  className,
  editorValue,
  clearFocus,
  expanded,
  setExpanded,
}) => {
  const controlsContext = useContext(ControlsContext);

  const [selected, setSelected] = useState(null);

  console.log("selected", selected);

  const [isFull, setIsFull] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFull(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const toggleSelected = (e) => {
    e.preventDefault();
    const toggle = e.currentTarget.dataset.toggle;
    if (selected === toggle) {
      setSelected(null);
    } else {
      setSelected(toggle);
    }
  };

  const toggleFull = () => {
    if (!isFull) {
      setIsFull(true);
      goFullscreen();
    } else {
      setIsFull(false);
      exitFullscreen();
    }
  };

  const renderSelected = () => {
    switch (selected) {
      case "save":
        return <SaveMenu editorValue={editorValue} />;
      case "open":
        return <OpenMenu editorValue={editorValue} />;
      case "prefs":
        return (
          <div>
            <FormGroup>
              <label>Theme</label>
              <select
                defaultValue={localStorage.getItem("theme")}
                onChange={(e) => {
                  const eValue = e.target.value;
                  localStorage.setItem("theme", eValue);
                  controlsContext.setIsDark(eValue === "dark" ? true : false);
                }}
              >
                <option value={"light"}>Light</option>
                <option value={"dark"}>Dark</option>
              </select>
            </FormGroup>
            <FormGroup>
              <label>Focus Mode</label>
              <select
                defaultValue={localStorage.getItem("focus")}
                onChange={(e) => {
                  if (e.target.value === "false") {
                    clearFocus();
                  }
                  localStorage.setItem("focus", e.target.value);
                }}
              >
                <option value={"false"}>Disabled</option>
                <option value={"true"}>Enabled</option>
              </select>
            </FormGroup>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div className={className}>
      <MenuButton
        onClick={() => {
          setSelected(null);
          setExpanded((expanded) => !expanded);
        }}
      >
        {expanded ? <CrossIcon /> : <MenuIcon />}
      </MenuButton>
      <MenuPanel display={expanded ? true : false}>
        <UnorderedList>
          <li>
            <a
              data-toggle={"save"}
              className={selected === "save" ? "selected" : ""}
              onClick={toggleSelected}
            >
              <SaveIcon />
            </a>
          </li>
          <li>
            <a
              data-toggle={"open"}
              className={selected === "open" ? "selected" : ""}
              onClick={toggleSelected}
            >
              <OpenIcon />
            </a>
          </li>
          <li>
            <a
              data-toggle={"prefs"}
              className={selected === "prefs" ? "selected" : ""}
              onClick={toggleSelected}
            >
              <CogIcon />
            </a>
          </li>
          <li>
            <a
              data-toggle={"mix"}
              className={selected === "mix" ? "selected" : ""}
              onClick={toggleSelected}
            >
              <MixIcon />
            </a>
          </li>
          <li>
            <a
              className={isFull ? "selected" : ""}
              onClick={(e) => {
                e.preventDefault();
                setSelected(null);
                toggleFull();
              }}
            >
              {isFull ? <MinIcon /> : <MaxIcon />}
            </a>
          </li>
        </UnorderedList>
        <DetailPanel>
          <Mixer display={selected === "mix"} />
          {selected !== null && (
            <div className="container">{renderSelected()}</div>
          )}
        </DetailPanel>
      </MenuPanel>
    </div>
  );
};

const goFullscreen = () => {
  var elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
}

const StyledMenu = styled(Menu)`
  background-color: ${(props) => props.theme.colors.background};
  font-family: "Merriweather", serif;
  font-size: 24px;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 2em;
  padding-top: 2em;
  font-family: "Open Sans", sans-serif;
  z-index: 1;
  height: 100%;

  a {
    cursor: pointer;
  }

  a:not(.irrelevant) {
    display: flex;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.5rem;
  }

  a: hover:not(.irrelevant) {
    background-color: ${(props) => props.theme.colors.menuHover};
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .selected {
    svg {
      path {
        fill: ${(props) => props.theme.colors.buttonHover};
      }
    }
  }

  svg {
    path {
      fill: ${(props) => props.theme.colors.button};
    }
  }

`;

const MenuButton = styled.a`
  font-size: 32px;
  padding: 0;
  cursor: pointer;
  display: inline-flex; // Add this line
  width: fit-content;
`;

const DetailPanel = styled.div`
  .container {
    width: 12em;
    padding-right: 2em;
  }
  margin-left: 2em;
  margin-top: 0.1em;
  button {
    background: none;
    background-color: rgb(98, 98, 98);
    padding: 0.5em 0.8em;
    border: none;
    color: white;
    font-size: 1em;
    border-radius: 3px;
    cursor: pointer;
  }

  button:hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
    color: ${(props) => props.theme.colors.buttonHoverText};
  }
  font-size: 15px;
`;

const MenuPanel = styled.div`
  display: ${(props) => (props.display ? "flex" : "none")};
`;

export default StyledMenu;
