import React, { useState } from "react";
import styled from "styled-components";
import Editor from "./components/Editor";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import { ControlsProvider } from "./components/ControlsContext";

const App = ({ className }) => {
  const theme = localStorage.getItem("theme");
  const [isDark, setIsDark] = useState(theme === "dark" ? true : false);

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <GlobalStyle />
      <div className={className}>
        <AppContainer>
          <ControlsProvider
            value={{
              setIsDark: setIsDark,
              isDark: isDark,
            }}
          >
            <MessageContainer>
              <h1>Tranquill writer is only available for larger screen sizes.</h1>
            </MessageContainer>
            <Editor></Editor>
          </ControlsProvider>
        </AppContainer>
      </div>
    </ThemeProvider>
  );
};

const StyledApp = styled(App)``;

const AppContainer = styled.div`
  margin: 0 auto;
  padding: 5% 0;
  width: 50%;
`;

const MessageContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  font-family: "Merriweather", serif;
  
  h1 {
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
  }

  visibility: hidden;

  @media (max-width: 1020px) {
    visibility: visible;
  }
`;

const GlobalStyle = createGlobalStyle`
html,
body {
  height: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.colors.background};
}

::backdrop {
  background-color: ${(props) => props.theme.colors.background};
}

`;

export default StyledApp;
