import React, { useMemo, useState, useCallback } from "react";
import { createEditor, Editor, Transforms, Text } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import styled from "styled-components";
import Menu from "./Menu";
import HoveringToolbar from "./HoveringToolbar";

const OurEditor = ({ className }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [expanded, setExpanded] = useState(false);
  const [prevFocus, setPrevFocus] = useState(null);

  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ]);

  const clearFocus = () => {
    const offset =
      editor.children[editor.children.length - 1].children[0].text.length;

    Transforms.setNodes(
      editor,
      { focused: false },
      {
        at: {
          anchor: { path: [0, 0], offset: 0 },
          focus: {
            path: [editor.children.length - 1, 0],
            offset: offset,
          },
        },
        match: (n) => Text.isText(n),
      }
    );
  };

  const focusTransforms = () => {
    const focusEnabled = localStorage.getItem("focus") === "true";

    if (prevFocus !== editor.selection.anchor.path[0] && focusEnabled) {
      const offset =
        editor.children[editor.children.length - 1].children[0].text.length;

      Transforms.setNodes(
        editor,
        { focused: true },
        {
          at: {
            anchor: { path: [0, 0], offset: 0 },
            focus: {
              path: [editor.children.length - 1, 0],
              offset: offset,
            },
          },
          match: (n) => Text.isText(n),
        }
      );

      Transforms.setNodes(
        editor,
        { focused: false },
        { at: editor.selection.anchor.path }
      );
    }

    setPrevFocus(editor.selection.anchor.path[0]);
  };

  const onKeyDown = (e) => {
    if (editor.selection) {
      focusTransforms();
    }

    if (e.ctrlKey) {
      switch (e.key) {
        case "b":
          toggleMark(editor, "bold");
          break;
        case "i":
          toggleMark(editor, "italic");
          break;
        case "1":
          toggleMark(editor, "heading", "h1");
          break;
        case "2":
          toggleMark(editor, "heading", "h2");
          break;
        case "3":
          toggleMark(editor, "heading", "h3");
          break;
        default:
          break;
      }
    }
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    const focusedClassName = leaf.focused ? "focused" : " ";
    return (
      <span
        {...attributes}
        className={`${focusedClassName} ${leaf.heading}`}
        style={{
          fontWeight: leaf.bold ? "bold" : "normal",
          fontStyle: leaf.italic ? "italic" : "normal",
        }}
      >
        {children}
      </span>
    );
  }, []);

  return (
    <div className={className}>
      <Menu
        expanded={expanded}
        setExpanded={setExpanded}
        clearFocus={clearFocus}
        editorValue={{ get: value, set: setValue }}
      ></Menu>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => setValue(value)}
      >
        <HoveringToolbar toggleMark={toggleMark} isMarkActive={isMarkActive} />
        <Editable
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          onClick={() => {
            if (editor.selection) {
              focusTransforms();
            }
          }}
          style={{ height: "82vh" }}
          onFocus={() => setExpanded(false)}
          autoFocus={true}
        />
      </Slate>
    </div>
  );
};

const toggleMark = (editor, mark, activeVal = true) => {
  const isActive = isMarkActive(editor, mark, activeVal);

  if (isActive) {
    editor.removeMark(mark);
  } else {
    editor.addMark(mark, activeVal);
  }
};

const isMarkActive = (editor, mark, activeVal) => {
  const marks = Editor.marks(editor);

  if (!marks) {
    return false;
  }

  return marks[mark] === activeVal;
};

const StyledEditor = styled(OurEditor)`
  font-family: "Merriweather", serif;
  font-size: 20px;
  color: ${(props) => props.theme.colors.editorText};

  .focused {
    color: ${(props) => props.theme.colors.focusedText};
  }

  .h1 {
    font-size: 49px;
  }

  .h2 {
    font-size: 40px;
  }

  .h3 {
    font-size: 30px;
  }
`;

export default StyledEditor;
