import parseRTF from "rtf-parser";
import { Node } from "slate";

export function downloadFile(data, filename, type) {
  var file = new Blob([data], { type: type });

  if (window.navigator.msSaveOrOpenBlob)
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export const serializeRtf = (document) => {
  return (
    document
      .map((n) => {
        // eslint-disable-next-line array-callback-return
        const children = n.children.map((c) => {
          if (c.heading) {
            if (c.heading === "h1") {
              return `\\fs49 ${c.text}`;
            } else if (c.heading === "h2") {
              return `\\fs40 ${c.text}`;
            } else if (c.heading === "h3") {
              return `\\fs30 ${c.text}`;
            }
          } else if (c.bold) {
            return `\\fs20\\b ${c.text}\\b0`;
          } else if (c.italic) {
            return `\\fs20\\i ${c.text}\\i0`;
          } else return `\\fs20${c.text}`;
        });

        return children.join("");
      })
      .join("\\par ") + "\\par "
  );
};

const parseRtfPromise = (...args) => {
  return new Promise((resolve, reject) => {
    parseRTF.string(...args, (err, doc) => {
      if (err) return reject(err);
      resolve(doc);
    });
  });
};

export const deserializeRtf = async (string) => {
  const doc = await parseRtfPromise(string);
  const document = doc.content.map((p) => {
    const children = p.content.map((r) => {
      const text = { text: r.value };
      if (r.style.fontSize) {
        if (r.style.fontSize === 49) {
          text.heading = "h1";
        } else if (r.style.fontSize === 40) {
          text.heading = "h2";
        } else if (r.style.fontSize === 30) {
          text.heading = "h3";
        }
      }
      if (r.style.bold) {
        text.bold = true;
      } else if (r.style.italic) {
        text.italic = true;
      }
      return text;
    });
    if (children.length > 0) {
      return {
        type: "paragraph",
        children: children,
      };
    } else {
      return {
        type: "paragraph",
        children: [{ text: "" }],
      };
    }
  });

  return document;
};

export const deserializePlain = (string) => {
  return string.split("\n").map((line) => {
    return {
      children: [{ text: line }],
    };
  });
};

export const serializePlain = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};
